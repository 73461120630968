export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')];

export const server_loads = [0];

export const dictionary = {
	"/": [~5],
	"/account/login": [~6,[2]],
	"/account/oauth-error": [7,[2]],
	"/account/password-reset": [~8,[2]],
	"/account/register": [~9,[2]],
	"/account/reset-password": [~10,[2]],
	"/account/settings": [~11,[2]],
	"/admin": [~12],
	"/create": [~13],
	"/dashboard": [~14],
	"/docs": [15,[3]],
	"/docs/attribution": [16,[3]],
	"/docs/develop": [17,[3]],
	"/docs/import-from-kahoot": [18,[3]],
	"/docs/pow": [19,[3]],
	"/docs/privacy-policy": [20,[3]],
	"/docs/roadmap": [21,[3]],
	"/docs/self-host": [22,[3]],
	"/docs/tos": [23,[3]],
	"/edit": [~24],
	"/explore": [25],
	"/import": [~26],
	"/play": [~27],
	"/practice": [28],
	"/search": [29],
	"/view/[quiz_id]": [~30,[],[4]]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};